.carousel-item {
  cursor: pointer;
  transition: 1200ms all;
  transform-origin: center;  
}

/* Make sure the hover effect is visible */
.carousel-item:hover {
  z-index: 1000;
  transform: scale(1.5);
}

.carousel-item__img {
  border-radius: 20px;
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the container */

}


