.sectionImg {
  width: 100% !important;
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.75) !important;
  border-radius: 15px !important;
}

.sectionBtn:hover {
  border: none;
  background-color: #FA6D15 !important; /* Replace with your specific color code or CSS variable */
  color: white !important; /* Replace with your specific color code or CSS variable */
}

.sectionBtn .material-icons-round {
  transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
}

.sectionBtn:hover .material-icons-round {
  transform: translateX(50px);
  opacity: 0;
}



.activeTabClass{
  background-color: rgba(250,109,21, .86) !important;
  border: 1px solid black !important;
  box-shadow: 0 7px 20px 5px #00000088;
}

.nonActiveTabClass{
  background-color: transparent !important;
  border: 1px solid black !important;
}

.commonTextColor{
  color: #FA6D15 !important;
}

.commonTextOpColor{
  color: rgba(250,109,21, 1) !important;
}

.commonBgColor{
  color: #FA6D15 !important;
}

.bannerContainer {
  position: relative  !important;
  width: 100% !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  overflow: hidden !important;
  box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.6) !important;
  min-height: 20vh;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.profileContainer {
  position: relative  !important;
  width: 100% !important;
  background-size: cover !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  overflow: hidden !important;
  box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.6) !important;
  min-height: 20vh;
  display: flex !important;
  align-items: center !important;
}

@media (min-width: 600px) {
  .bannerContainer, .profileContainer {
    min-height: 30vh;
  }
}

@media (min-width: 801px) {
  .bannerContainer, .profileContainer {
    min-height: 60vh;
  }
}

@media (min-width: 1201px) {
  .bannerContainer, .profileContainer {
    min-height: 75vh;
  }
}


.bannerCoverImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 20vh;
  object-fit: cover; 
}

.bannerCoverImg img {
  width: 100%;   
}

@media (min-width: 600px) {
  .bannerCoverImg {
    min-height: 30vh;
  }
}

@media (min-width: 801px) {
  .bannerCoverImg {
    min-height: 60vh;
  }
}

@media (min-width: 1201px) {
  .bannerCoverImg {
    min-height: 75vh;
  }
}

.bannerText{
  color: #FA6D15 !important;
  font-weight: bold !important;
  text-align: center !important;
}

@keyframes hideSlash {
  0% { opacity: 0; }
  20% { opacity: 1; }
  72% { opacity: 1; margin-left: -45px; }
  100% { opacity: 0; margin-left: -70px; }
}

.bannerSlashText {
  animation: hideSlash 3s forwards !important;
}

@media (max-width: 600px) {
  .bannerSlashText {
    font-size: 7.5rem !important;
  }
  .bannerNameText {
    font-size: 4.5rem !important;
  }
  .bannerWorkProfileText {
    font-size: 3rem !important;
  }
  .bannerContactUsText, .bannerSubmissionText {
    font-size: 3.5rem !important;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .bannerSlashText {
    font-size: 8rem !important;
  }
  .bannerNameText, .bannerWorkProfileText, .bannerContactUsText, .bannerSubmissionText {
    font-size: 5rem !important;
  }

}

@media (min-width: 801px) {
  .bannerSlashText {
    font-size: 12rem !important;
  }
  .bannerNameText {
    font-size: 9rem !important;
  }
  .bannerWorkProfileText, .bannerContactUsText, .bannerSubmissionText {
    font-size: 8rem !important;
  }
}

@media (min-width: 901px) {
  .bannerSlashText {
    font-size: 15rem !important;
  }
  .bannerNameText, .bannerContactUsText, .bannerSubmissionText {
    font-size: 9rem !important;
  }
  .bannerWorkProfileText {
    font-size: 7.8rem !important;
  }
}

@keyframes reveal{
  0% { opacity: 0; width: 0; }
  15% { opacity: 0.9; width: 0; }
  30% { width: auto; }
  80% { opacity: 0.8; }
  95% { opacity: 0.8; }
  100% { opacity: 0.8; width: auto; }
}

.bannerRevealText {
  opacity: 0;
  width: 0 ;
  overflow: hidden;
  animation: reveal 7s forwards !important;
}

@keyframes slidein {
  0% { margin-left: -800px; }
  20% { margin-left: -800px; }
  38% { margin-left: 0;  }
  100% { margin-left: 0;  }
}

.bannerNameText, .bannerWorkProfileText, .bannerContactUsText, .bannerSubmissionText {
  animation: slidein 7s forwards;
}

@keyframes moveFromTopLeft {
  0% {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 0);
  }
  75% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}

@keyframes moveFromBottomRight {
  0% {
    opacity: 0;
    clip-path: polygon(100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    opacity: 1;
    clip-path: polygon(100% 100%, 100% 0, 0 100%);
  }
}

.bannerActorTopLeft {
  animation: moveFromTopLeft 2s forwards;
}

.bannerActorBottomRight {
  animation: moveFromBottomRight 2s forwards;
}

@keyframes polygon{
  0% {
    opacity: 0; 
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  90% { 
    opacity: .9; 
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  100% { 
    opacity: .9; 
    clip:none;
  }
}

.bannerWorkProfile{
  text-align: center;
  justify-content: center;
  animation: polygon 2s forwards;
}

@keyframes circle{
  0% {
    opacity: 0; 
    clip-path: circle(0%);

  }
  100% { 
    opacity: .9; 
    clip-path: circle(100%);
  }
}

.bannerContactUs{
  text-align: center;
  justify-content: center;
  animation: circle 2s forwards;
}

@keyframes circle2{
  0% {
    opacity: 0; 
    clip-path: circle(50%);
  }
  50% { 
    clip-path: circle(30%);
  }
  100% { 
    opacity: .9; 
    clip-path: circle(100%);
  }
}

.bannerSubmission{
  text-align: center;
  justify-content: center;
  animation: circle2 2s forwards;
}

@keyframes revealLeftToRight{
  0% {
    clip-path: inset(0 100% 0 0);
    
  }
  100% { 
    clip-path: inset(0 0 0 0);
  }
}

.cardLogo{
  animation: revealLeftToRight 3s forwards  !important;
}

@keyframes topDrop {
  0% { margin-top: -95%; }
  100% { margin-top: 10;  }
}

.profileImage {
  /* animation: topDrop 2s forwards; */
}

@keyframes imagesReveal {
  0% {
    opacity: 0; 
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  100% { 
    opacity: 1; 
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.galleryImages {
  animation: imagesReveal 1s forwards;
}

.submissionError, .contactUsError{
  color: red;
  font-size: small;
}

.Toastify__toast {
  font-size: 16px;
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(143, 141, 141, .75);
  border-radius: 30px;
  width: 250px;
  padding: 1px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.toggle-wrapper {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  transition: 0.3s ease;
  background-color: transparent;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}

.toggle-wrapper.active {
  background-color: #000;
  color: #fff;
}

.swiper-button-prev, .swiper-button-next{
  color: black !important;
  font-weight: bold;
}
